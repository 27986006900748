import { render, staticRenderFns } from "./merchantOnlineRequest.html?vue&type=template&id=5213db14&scoped=true&"
import script from "./merchantOnlineRequest.js?vue&type=script&lang=js&"
export * from "./merchantOnlineRequest.js?vue&type=script&lang=js&"
import style0 from "./merchantOnlineRequest.scss?vue&type=style&index=0&id=5213db14&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5213db14",
  null
  
)

export default component.exports